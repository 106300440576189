interface Envs {
  [key: string]: string
}

type WindowWithEnvs = Window &
  typeof globalThis & {
    _env_: Envs
  }

const BUILD_TIME_ENVS = process.env.BUILD_ENVS as unknown as Envs

export const getValue = (resource: string) => {
  let globalEnvs: Envs | undefined
  let processEnvs
  let buildEnvs: Envs | undefined

  if (typeof globalThis !== 'undefined') {
    globalEnvs = (<WindowWithEnvs>globalThis)._env_ ?? {}
  }

  if (typeof process !== 'undefined') {
    processEnvs = process.env ?? {}
  }

  if (typeof BUILD_TIME_ENVS !== 'undefined') {
    buildEnvs = BUILD_TIME_ENVS
  }

  return globalEnvs?.[resource] ?? processEnvs?.[resource] ?? buildEnvs?.[resource] ?? ''
}

const shouldUseEnvVariable = () => {
  if (process.env.SHOULD_USE_ENV_VARIABLE) {
    return true
  }
  const isDevOrIsTest = () =>
    ['test', 'development'].includes(process.env.NODE_ENV || '') ||
    process.env.DEPLOY_ENV === 'development'
  const isSSR = () => typeof window === 'undefined'
  return isDevOrIsTest() || isSSR()
}

const getApiUrl = () => {
  const hostHasChilipiper = () => {
    const hosts = [
      'chilipiper.io',
      'chilipiper.com',
      'na.chilipiper.com',
      'chilipiper.cool',
      'chilipiper.in',
    ]
    return hosts.some(host => {
      return window.location.host.includes(host)
    })
  }
  // We need cookie session to be stored and reverse proxy doesn't store it
  if (shouldUseEnvVariable() || !hostHasChilipiper()) {
    return getValue('REACT_APP_API_RESOURCE') || process.env.REACT_APP_API_RESOURCE || ''
  }
  return '/api'
}

export const endpoint = {
  auth: (): string => getValue('REACT_APP_AUTH_RESOURCE'),
  x: (): string => getValue('REACT_APP_X_RESOURCE'),
  api: getApiUrl,
  apiRoot: () => getValue('REACT_APP_API_RESOURCE'),
  edge: (): string => getValue('REACT_APP_EDGE_RESOURCE'),
  reporting: (): string => getValue('REACT_APP_REPORTING_API_RESOURCE'),
  profiles: (): string => getValue('REACT_APP_PROFILES_RESOURCE'),
  profilesUpload: (): string => getValue('REACT_APP_PROFILES_UPLOAD_RESOURCE'),
  addIn: (): string => getValue('REACT_APP_ADD_IN_RESOURCE'),
  inbox: (): string => getValue('REACT_APP_INBOX_API_RESOURCE'),
  track: (): string => getValue('REACT_APP_TRACK_RESOURCE'),
  newInstantBooker: (): string => getValue('REACT_APP_NEW_IB_RESOURCE'),
  homebase: () => getValue('REACT_APP_REVERSE_PROXY'),
  fire: () =>
    shouldUseEnvVariable() ? getValue('REACT_APP_FIRE_RESOURCE') : window.location.origin,
}

export const secrets = {
  extensionId: 'digckgagekofoajjlaeceojmdokllfmf',
  intercom: getValue('REACT_APP_INTERCOM_RESOURCE'),
  stripePublicKey: getValue('REACT_APP_STRIPE_PUBLIC_KEY'),
  rolloutApiKey: getValue('REACT_APP_ROLLOUT_KEY'),
  cannyAppId: getValue('REACT_APP_CANNY_APP_ID'),
  fullstoryOrg: 'ZH104',
  segment: 'KjKq1awRV5V3FBmBQDW1UONQFtGjOwBH',
  sprigEnvironmentId: getValue('REACT_APP_SPRIG_ENVIRONMENT_ID'),
  snowplowEndpoint: getValue('REACT_APP_SNOWPLOW_ENDPOINT'),
}

export const sentry = {
  env: getValue('REACT_APP_SENTRY_ENVIRONMENT'),
  adminCenterDSN: 'https://5f426adf29504ccda371f0816836a7bf@o94797.ingest.sentry.io/6031535',
  adminCenterFireDSN:
    'https://a59c52ec39394ebcad3b1f3e3c190c25@o94797.ingest.sentry.io/4503971410214912',
  bookingAppLegacyDSN: 'https://c92b84893fdb4bce954bca38fdcc94c8@o94797.ingest.sentry.io/5752892',
  bookingAppDSN:
    'https://a847962f04efd424281204e298c1fc71@o94797.ingest.us.sentry.io/4507022524153856',
  extensionDSN: 'https://1de39c3afc444944b52e41b466755459@o94797.ingest.sentry.io/5583957',
  instantBookerDSN: 'https://9957661be720400f99197a25ed75aaec@o94797.ingest.sentry.io/5583170',
  instantBookerNewDSN: 'https://0a4ef27bf22846cba89fb94b12f3a1d8@o94797.ingest.sentry.io/6146255',
  outlookAddInDSN: 'https://fc1bff48648d4a51bb460f9f8f5a1acd@o94797.ingest.sentry.io/6300107',
  chilicalDSN: 'https://b39db161b5d144feb78f6aaed5b225ee@o94797.ingest.sentry.io/6670575',
  release: getValue('SENTRY_RELEASE_COMMIT'),
  releaseVersion: getValue('SENTRY_RELEASE_VERSION'),
}

export const marketing = {
  script: getValue('REACT_APP_MARKETING_SCRIPT'),
}

export const testing = {
  sessionId: '5feb879d58d3460001b706b9',
}

export const environment = {
  deploy: getValue('DEPLOY_ENV'),
  node: getValue('NODE_ENV'),
}

export const urls = {
  help: 'https://help.chilipiper.com/hc/en-us',
  university: 'https://university.chilipiper.com',
  suggest: 'https://suggest.chilipiper.com',
}

export const version = () => getValue('VERSION')
